<template>
  <!-- 加入 -->
  <Layout>
    <div class="md-join">
      <div class="base-width">
        <div class="text-center" v-ani.fade-up>
          <div class="title s26 font-medium">全网协同 任务众包</div>
          <div class="title-sub s16 color-6">无论建筑工程产业的何种身份，易筑总能为您精准匹配对应的专属服务</div>
        </div>
        <div class="list" :key="listKey">
          <template v-for="(item, index) in listArr">
            <div class="li c-card flex-middle" :key="index" v-ani.fade-up>
              <ImgRatio class="lf c-card" :src="item.img" :width="130" :height="173" fit="contain" />
              <div class="text">
                <div class="tit s18 font-medium">{{ item.title }}</div>
                <div class="desc s16 color-9">{{ item.desc }}</div>
              </div>
              <BaseButton v-if="item.apply_status == 0" class="status" icon='right' tag='router-link' :to='item.link'
                style-type='border'>
                去认证
              </BaseButton>
              <BaseButton v-if="item.check_status == 3" class="status" icon='right' tag='router-link' :to='item.link'
                style-type='border'>
                审核不通过
              </BaseButton>
              <BaseButton v-if="item.check_status == 2" class="status" color='green' @click="jumpClick(item.backPath)">
                进入后台
              </BaseButton>
              <BaseButton v-if="item.apply_status == 1 && item.check_status == 1" class="status">
                等待审核
              </BaseButton>
              <!-- <BaseButton v-if="item.status == 1" class="status" icon='right' tag='router-link' :to='item.link'
                style-type='border'>
                去认证
              </BaseButton>
              <BaseButton v-if="item.status == 2" class="status" color='green' tag='router-link' :to='item.link'>
                进入后台
              </BaseButton>
              <BaseButton v-if="item.status == 3" class="status">
                等待审核
              </BaseButton> -->

              <!-- <router-link to="" class="s16 status status-1 hn-btn-blue-plain flex-center" v-if="item.status == 1">
                <span>去认证</span><span class="icon"></span>
              </router-link> -->
              <!-- <router-link to="" class="s16 status status-2 hn-btn-green" v-else-if="item.status == 2">
                <span>进入后台</span>
              </router-link> -->
              <!-- <div class="s16 status status-3 hn-btn-blue" v-else-if="item.status == 3">
                <span>等待审核</span>
              </div> -->
            </div>
          </template>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { SettledCheckApi } from "@/request/api/user";
export default {
  created() {
    SettledCheckApi().then(({ status, data }) => {
      if (status == 200) {
        // 1=工程项目；2=材料供应商；3=战略采购商； 4=招标企业；5=配套服务商；6=供应链金融企业；7=专业分包，8=劳务分包
        if (data.type4.type == 4) {
          this.listArr[0].apply_status = data.type4.apply_status; //0=未申请，1=已申请
          this.listArr[0].check_status = data.type4.check_status; //状态：1待审核2审核通过3审核不通过
          this.listArr[0].go_form = data.type4.go_form; //0=不需要进入表单，1=进入表单
        }
        if (data.type3.type == 3) {
          this.listArr[1].apply_status = data.type3.apply_status;
          this.listArr[1].check_status = data.type3.check_status;
          this.listArr[1].go_form = data.type3.go_form;
        }
        if (data.type2.type == 2) {
          this.listArr[2].apply_status = data.type2.apply_status;
          this.listArr[2].check_status = data.type2.check_status;
          this.listArr[2].go_form = data.type2.go_form;
        }
        if (data.type1.type == 1) {
          this.listArr[3].apply_status = data.type1.apply_status;
          this.listArr[3].check_status = data.type1.check_status;
          this.listArr[3].go_form = data.type1.go_form;
        }
        if (data.type5.type == 5) {
          this.listArr[4].apply_status = data.type5.apply_status;
          this.listArr[4].check_status = data.type5.check_status;
          this.listArr[4].go_form = data.type5.go_form;
        }
        if (data.type6.type == 6) {
          this.listArr[5].apply_status = data.type6.apply_status;
          this.listArr[5].check_status = data.type6.check_status;
          this.listArr[5].go_form = data.type6.go_form;
        }
        if (data.type7.type == 7) {
          this.listArr[6].apply_status = data.type7.apply_status;
          this.listArr[6].check_status = data.type7.check_status;
          this.listArr[6].go_form = data.type7.go_form;
        }
        if (data.type8.type == 8) {
          this.listArr[7].apply_status = data.type8.apply_status;
          this.listArr[7].check_status = data.type8.check_status;
          this.listArr[7].go_form = data.type8.go_form;
        }
        this.listKey++;
      }
    })
  },
  data() {
    return {
      listKey: 0,
      listArr: [
        {
          img: require('@/assets/images/man-1.jpg'),
          title: '招标企业',
          desc: '开通线上招标，覆盖全工程产业，享受安全保障。',
          status: 1,
          link: '/auth/enterprise'
        }, {
          img: require('@/assets/images/man-2.jpg'),
          title: '战略采购商',
          desc: '一站式采购，工程材料全品类，集采账期加优惠。',
          status: 2,
          link: '/auth/purchase',
          backPath: `https://u.dcyijian.com/#/login?token=${localStorage.getItem('token')}`
        }, {
          img: require('@/assets/images/man-3.jpg'),
          title: '材料供应商',
          desc: '免费开通线上店铺，拓宽展示渠道获取海量订单。',
          status: 3,  // 3
          link: '/auth/material',
          backPath: `https://sup.dcyijian.com/#/login?token=${localStorage.getItem('token')}`
        }, {
          img: require('@/assets/images/man-4.jpg'),
          title: '工程项目',
          desc: '工程项目定点采购，专属服务省时省心降本提质增效。',
          status: 1,
          link: '/auth/project'
        }, {
          img: require('@/assets/images/man-5.jpg'),
          title: '工程配套服务商',
          desc: '各类工程配套服务商免费入驻，拓宽展示渠道，通过平台获取海量订单。',
          status: 1,
          link: '/auth/service'
        }, {
          img: require('@/assets/images/man-6.jpg'),
          title: '供应链金融企业',
          desc: '为工程项目提供材料保供等供应链金融服务支持。',
          status: 1,
          link: '/auth/finance'
        }, {
          img: require('@/assets/images/man-7.jpg'),
          title: '专业分包',
          desc: '各工程企业及专业分包商免费入驻，拓展渠道资源，对接各类分包工程。',
          status: 1,
          link: '/auth/pro-sub'
        }, {
          img: require('@/assets/images/man-8.jpg'),
          title: '劳务分包',
          desc: '各劳务专业班组及建筑劳务公司免费入驻，拓展渠道资源，对接各类工程劳务。',
          status: 1,
          link: '/auth/labor-sub'
        },
      ]
    };
  },
  methods: {
    jumpClick(path) {
      if (!path) {
        this.$message.warning('功能暂未开放');
        return;
      }
      window.open(path, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
.md-join {
  padding-top: .36rem;
  padding-bottom: .2rem;


  .title-sub {
    margin-top: .11rem;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: .27rem;

    .li {
      width: 7.9rem;
      padding: .3rem .5rem .3rem .3rem;
      margin-bottom: .2rem;

      &:hover {
        .lf {
          ::v-deep .img {
            transform: scale(1.1);
          }
        }
      }
    }

    .lf {
      width: 1.3rem;
      margin-right: .39rem;
    }

    .text {
      flex: 1;

      .desc {
        margin-top: .156rem;
        line-height: calc(30 / 16 * 1em);
      }
    }

    .status {
      margin-left: 0.5rem;
      min-width: 1rem;
      // line-height: calc(40 / 16 * 1em);
      // border-radius: .04rem;
      // padding: 0 1em;
      // text-align: center;
      // &-1{
      //   line-height: calc(38 / 16 * 1em);
      //   .icon{
      //     display: inline-block;
      //     width: .06rem; height: .1rem;
      //     background: url(~@/assets/images/icon-right3.png) no-repeat center / contain;
      //     margin-left: .1rem;
      //   }
      //   &:hover{
      //     .icon{
      //       background-image: url(~@/assets/images/icon-right4.png);
      //     }
      //   }
      // }
    }
  }
}
</style>